<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"

        title="Delete Game"
        @ok.prevent="deleteGame"
    >
      <div class="row">
        <div class="col-md-12">
          <span>Are you sure you want to delete this game ?</span>
        </div>
      </div>
    </b-modal>

    <div>
      <b-card
          v-if="games && !showNested"
          class="mb-0"

      >

        <h1>Games</h1>

        <div class="my-2">
          <!-- Table Top -->
          <div class="  row">

            <b-col class="d-flex flex-column -start  mb-1 mb-md-0"
                   cols="12" md="3"
            >
              <b-form-group label="Count">
                <v-select
                    v-model="perPage"
                    :clearable="false"
                    :options="perPageOptions"
                    class="per-page-selector  "
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="Search">
                <debouncer :placeHolder="`search by`" @setValue="getsearchValue"></debouncer>
              </b-form-group>
            </b-col>

          </div>
        </div>


        <b-table
            v-if="games"
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="games"
            :sort-by.sync="sortBy"
            bordered
            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            striped
        >

          <template #cell(imageUrl)="data">
            <img :src="data.item.imageUrl" alt="" style="width: 5rem;height: 5rem;object-fit: contain">
          </template>
          <template #cell(operation)="data">
            <div class="d-flex align-items-center  w-100">
              <b-link :to="`/apps/games/manage-game?gameId=${data.item.id}`">
                <feather-icon
                    class="text-primary"
                    icon="EditIcon"
                    size="20"
                />
              </b-link>
              <span v-b-modal.modal-delete class="cursor-pointer mx-1" @click="setSelectedGame(data.item)">
              <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
              />
              </span>
              <span class="cursor-pointer " @click="changeFirstLevel(data.item.id)">
              <feather-icon
                  class="text-info"
                  icon="ArrowRightIcon"
                  size="20"
              />
              </span>
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                class="d-flex align-items-center justify-content-center"
                cols="12"
                sm="12"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card v-else class="my-1">
        <keep-alive>
          <DynamicTable :gameId="selectedGameId" @resetComponent="resetComponent"></DynamicTable>
        </keep-alive>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import { BCard, BCol, BFormGroup, BLink, BModal, BOverlay, BPagination, BRow, BTable } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Helper from '@/libs/Helper'
import { DeleteGame, GetAllGames } from '@/libs/Api/games'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import Debouncer from '@/views/components/debouncer.vue'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'
import DynamicTable from '@/views/components/utilities/DynamicTable.vue'

export default {
  title: 'manage-games',
  name: 'manageGames',
  data() {
    return {
      required,
      games: null,
      sortBy: '',
      showOverlay: false,
      totalCount: null,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50],
      selectedGame: null,
      baseURL: Helper.baseUrl + 'media/gallery/profile/',
      myTableColumns: [
        {
          key: 'id',
          label: '#',
          sortable: true
        },
        {
          key: 'name',
          label: 'name',
          sortable: true
        },
        {
          key: 'imageUrl',
          label: 'main picture',
          sortable: true
        },
        {
          key: 'operation',
          label: 'operation',
        },
      ],
      selectedGameId: null,
      pageNumber: 1,
      searchQuery: '',
      showNested: false,
      count: 10,

    }
  },
  async created() {
    await Promise.all([
      this.getAllGames()
    ])
  },
  watch: {
    currentPage: function () {
      this.getAllGames()
    },
    perPage: function () {
      this.getAllGames()
    }
  },
  methods: {
      resetComponent() {
      this.showNested = false
    },
    changeFirstLevel(id) {
      this.showNested = true
      this.$router.push({
        path: this.$route.path,
        query: {
          level: 1
        }
      })
      this.selectedGameId = id
    },
    getsearchValue(val) {
      this.searchQuery = val
      this.getAllGames()
    },
    setSelectedGame(item) {
      this.selectedGame = JSON.parse(JSON.stringify(item))
    },
    async getAllGames() {
      let _this = this
      _this.showOverlay = true
      let getAllGames = new GetAllGames(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        search: _this.searchQuery
      }
      getAllGames.setParams(data)
      await getAllGames.fetch(function (content) {
        _this.games = content.data.data
        _this.totalCount = content.data.totalCount
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async deleteGame() {
      let _this = this
      _this.showOverlay = true
      let deleteGame = new DeleteGame(_this)
      deleteGame.setParams({ gameId: _this.selectedGame.id })
      await deleteGame.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `game successfully deleted`,
          },
        })
        _this.getAllGames()
        _this.$bvModal.hide('modal-delete')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },

  },

  components: {
    DynamicTable,
    DynamicBreadCrumb,
    Debouncer,
    BCard,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    BModal,
    BFormGroup,
    vSelect,
  },
}
</script>

<style scoped>


</style>
