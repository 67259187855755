<template>
  <b-form-input
      v-model="debouncedSearch"
      class="d-inline-block mr-1"
      :placeholder="placeHolder"
  />
</template>

<script>
import {BFormInput} from "bootstrap-vue";

export default {
  name: "debouncer",
  components: {BFormInput},
  props:{
    placeHolder:{
      type:String,
      required:false
    }
  },
  data() {
    return {
      searchQuery: '',
      timeout: null
    }
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.searchQuery
      },
      set(val) {

        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchQuery = val
        }, 600)

      }
    }
  },
  watch: {
    searchQuery: function (nv, ov) {
      this.$emit('setValue', this.searchQuery)
    },
  }
}
</script>

<style scoped>

</style>
