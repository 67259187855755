<template>
  <div>
    <b-overlay
        :show="overlay"
    >
      <transition appear name="slide-left">
        <b-card
            v-if="showData"
            class="mb-0"
        >

          <h1 v-if="nestedLevels[level]">{{ nestedLevels[level].name }}</h1>

          <div class="my-2">
            <!-- Table Top -->
            <div class="  row">
              <b-col class="d-flex justify-content-start align-items-center mb-1 mb-md-0"
                     cols="12" md="1"
              >
                <b-button
                    class="mt-1 mb-sm-0 mr-0 mr-sm-1"
                    variant="primary"
                    @click="levelBack"
                >
                  Back
                </b-button>
              </b-col>
              <b-col class="d-flex  flex-column mb-1 mb-md-0"
                     cols="12" md="3"
              >
                <b-form-group label="Count">
                  <v-select
                      v-model="perPage"
                      :clearable="false"
                      :options="perPageOptions"
                      class="per-page-selector  "
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group label="Search">

                  <debouncer :placeHolder="`search by`" @setValue="getSearchValue"></debouncer>
                </b-form-group>
              </b-col>

            </div>
          </div>


          <b-table
              v-if="tableData"
              ref="refUserListTable"
              :fields="myTableColumns"
              :items="tableData"
              bordered
              class="position-relative"
              empty-text="Nothing found !"
              primary-key="id"
              responsive
              show-empty
              sort-by.sync
              striped
          >

            <template #cell(operation)="data">
              <div class="d-flex align-items-center  w-100">

            <span v-if="level!==6" class="cursor-pointer mx-1" @click="changeLevel(data.item.id,false)">
              <feather-icon
                  class="text-info"
                  icon="ArrowRightIcon"
                  size="20"
              />
              </span>
                <span class="cursor-pointer mx-1" @click="changeLevel(data.item.id,true)">
              <feather-icon
                  class="text-primary"
                  icon="EditIcon"
                  size="20"
              />
              </span>
              </div>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                  class="d-flex align-items-center justify-content-center"
                  cols="12"
                  sm="12"
              >
                <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
              </b-col>
              <!-- Pagination -->
              <b-col
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  cols="12"
                  sm="6"
              >
                <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="totalCount"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </transition>

    </b-overlay>

  </div>

</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'
import Debouncer from '@/views/components/debouncer.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { GetNestedGames } from '@/libs/Api/games'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DynamicTable',
  props: {

    gameId: {
      type: Number
    }
  },
  data() {
    return {
      totalCount: null,
      overlay: false,
      currentPage: 1,
      searchQuery: '',
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50],
      showData: false,
      tableData: null,
      level: 1,
      nestedLevels: {
        2: {
          query: 'gameId',
          route: '/apps/games/game-service-categories/manage-game-service-category',
          id: null,
          name: 'Game Service Categories'
        },
        3: {
          query: 'gameServiceCategoryId',
          route: '/apps/games/game-service-categories/manage-game-service-category',
          // /apps/games/game-service-categories/game-service/manage-game-services
          id: null,
          name: 'Game Services'
        },
        4: {
          query: 'gameServiceId',

          route: '/apps/games/game-service-categories/game-service/manage-game-services',
          id: null,
          name: 'Product'
        },
        5: {
          query: 'productId',
          route: '/apps/games/game-service-categories/game-service/products/manage-product',
          id: null,
          name: 'Product Fields'
        },
        6: {
          query: 'productFieldDetailId',
          route: '/apps/games/game-service-categories/game-service/products/product-field/manage-product-field',
          // /apps/games/game-service-categories/game-service/products/product-field/product-feature/edit-product-feature
          id: null,
          name: 'Product Features'
        },
        7: {
          query: 'productFeatureId',
          route: '/apps/games/game-service-categories/game-service/products/product-field/product-feature/edit-product-feature',
          // /apps/games/game-service-categories/game-service/products/product-field/product-feature/edit-product-feature
          id: null,
          name: 'Product Features'
        },
      },
      myTableColumns: [
        {
          key: 'id',
          label: '#'
        },
        {
          key: 'title',
          label: 'title'
        },
        {
          key: 'operation',
          label: 'operation'
        }
      ]
    }
  },

  watch: {
    gameId: {
      handler(val) {
        if (val) {
          this.changeLevel(val, false)
        }
      },
      immediate: true
    },
    perPage: function () {
      this.getNestedData(false)
    },
    currentPage: function () {
      this.getNestedData(false)
    }
  },
  methods: {

    levelBack() {
      if (this.level !== 2) {
        this.nestedLevels[this.level].id = null
        this.level--
        this.searchQuery = ''
        this.getNestedData(true)
      } else {
        this.$emit('resetComponent')
      }
    },
    changeLevel(id, changeRoute) {
      this.level++
      this.searchQuery = ''
      this.nestedLevels[this.level].id = id
      if (changeRoute) {
        this.makeRoute()
      } else {
        this.getNestedData(true)
      }
    },
    makeRoute() {
      let queries = {
        gameId: this.gameId
      }
      Object.values(this.nestedLevels)
          .forEach((item) => {
            if (item.id) {
              queries[`${item.query}`] = item.id
            }
          })
      console.log(queries)
      this.$router.push({
        path: `${this.nestedLevels[this.level].route}`,
        query: queries
      })
    },
    async getNestedData(slide) {
      let _this = this
      _this.overlay = true
      if (slide) {
        _this.showData = false
      }
      let getNestedGames = new GetNestedGames(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        search: _this.searchQuery,
        [`${this.nestedLevels[this.level].query}`]: this.nestedLevels[this.level].id
      }
      getNestedGames.setParams(data)
      await getNestedGames.fetch(function (content) {
        _this.tableData = content.data.pageResult.data
        _this.totalCount = content.data.pageResult.totalCount
        _this.overlay = false
        _this.showData = true
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.overlay = false
        _this.showData = true

      })
    },
    getSearchValue(search) {
      this.searchQuery = search
      this.getNestedData(false)
    },

  },
  components: {
    DynamicBreadCrumb,
    Debouncer,
    BCard,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
}
</script>

<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: .5s ease;
  opacity: 1;
  transform: translateX(0px);

}

.slide-left-enter,
.slide-left-leave-to {
  transition: .5s ease;
  transform: translateX(20px);
  opacity: 0;
}
</style>
